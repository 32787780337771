import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Panel, UserEditForm } from "components/common/old";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import { CloseOutlined, PlusOutlined, SmileOutlined } from "@ant-design/icons";
import { Wrapper } from "./Colleagues.styles";
import { Button, List, Typography } from "antd";
import { Link } from "react-router-dom";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import {
  colleagueAddActions,
  colleagueAddSelectors
} from "store/colleagues/add";
import { TPerson } from "types";
import { addColleague } from "store/colleagues/add/thunk";

export const Colleagues = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    colleagueAddSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);

  const getColleagues = useCallback(() => {
    user?.cabinet &&
      dispatch(getColleaguesByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const colleaguessData = useMemo(
    () =>
      (users || []).map((colleague) => ({
        value: colleague?.full_name,
        label: colleague?.position,
        icon: <SmileOutlined />,
        id: colleague?.user_id
      })),
    [users]
  );

  const canAdd = useMemo(
    () => user?.cabinet_role_id === 2 || user?.is_admin,
    [user?.cabinet_role_id, user?.is_admin]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onAddColleague = useCallback(
    (values: TPerson) => {
      dispatch(
        addColleague({
          /* TODO убрать установку cabinet_role_name, cabinet_role_description */
          ...{ cabinet_role_name: "", cabinet_role_description: "" },
          ...values,
          cabinet: user!.cabinet,
          cabinet_role_id: 1
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          getColleagues();
        });
    },
    [dispatch, getColleagues, onCancelAdd, user]
  );

  useEffect(() => {
    !users && getColleagues();
  }, [dispatch, getColleagues, users]);

  useEffect(() => {
    return () => {
      dispatch(colleagueAddActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Коллеги
      </Typography.Title>

      {canAdd && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      <Panel
        style={{
          display: "flex",
          flexDirection: "column"
        }}
      >
        {isAdding ? (
          <UserEditForm
            curUser={user}
            onSubmit={onAddColleague}
            isLoading={addIsLoading}
            error={addError}
          />
        ) : (
          <List
            dataSource={colleaguessData}
            renderItem={(item) => (
              <Link to={`${item?.id}`}>
                <List.Item>
                  <List.Item.Meta
                    avatar={item?.icon}
                    title={item?.value}
                    description={item?.label}
                  />
                </List.Item>
              </Link>
            )}
          />
        )}
      </Panel>
    </Wrapper>
  );
};
