import styled from "@emotion/styled";
import { Layout, Typography } from "antd";
import { DEVICE } from "constants/breakpoints";

export const LayoutStyled = styled(Layout)`
  height: 100%;
  min-height: 100%;
  flex-direction: row;
  background: #ffffff;

  @media ${DEVICE.LG} {
    flex-direction: column;
  }
`;
export const ImageWrapper = styled.div`
  height: 100%;
  min-height: 100%;
  flex: 1;
  position: relative;
  border-radius: 0 24px 24px 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${DEVICE.LG} {
    height: unset;
    min-height: unset;
    flex: unset;
    border-radius: 0 0 24px 24px;
    justify-content: flex-start;
  }

  @media ${DEVICE.SM} {
    display: none;
  }
`;
export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
`;
export const TextBlock = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: linear-gradient(180deg, transparent, #00182e);
  padding: calc(64px + 128px) 80px 64px;
  z-index: 1;

  @media ${DEVICE.XL} {
    gap: 8px;
    padding: calc(24px + 128px) 64px 24px;
  }
  @media ${DEVICE.LG} {
    max-height: 256px;
    padding: 24px 24px calc(24px + 96px);
    background: linear-gradient(0deg, transparent, #00182e);
  }
`;
export const Title = styled(Typography.Title)`
  margin: 0 !important;
  line-height: 40px !important;
  color: #ffffff !important;
  font-weight: 900 !important;

  @media ${DEVICE.XL} {
    font-size: 24px;
    line-height: 32px;
  }
`;
export const Subtitle = styled(Typography.Title)`
  margin: 0 !important;
  color: #ffffff !important;
  font-weight: 500 !important;

  @media ${DEVICE.XL} {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400 !important;
  }
`;
export const MainWrapper = styled.div`
  height: 100%;
  min-height: 100%;
  width: 640px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;

  @media ${DEVICE.LG} {
    height: unset;
    min-height: unset;
    width: 100%;
    flex: 1;
  }
`;
export const Header = styled.div`
  padding: 24px 56px;
  align-self: flex-start;

  @media ${DEVICE.LG} {
    padding: 48px 24px 24px;
  }

  @media ${DEVICE.SM} {
    padding: 16px 24px 16px;
  }
`;
export const Logo = styled.img`
  height: 32px;
  width: 195px;
  object-fit: cover;
`;
export const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: auto;
  width: 100%;
`;
export const MainContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 32px;
  padding: 0 56px;

  @media ${DEVICE.LG} {
    padding: 0 24px;
  }
`;
export const Footer = styled.div`
  padding: 24px 56px 24px;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media ${DEVICE.LG} {
    padding: 24px;
  }
`;
export const CredText = styled(Typography.Text)`
  font-size: 12px;
  line-height: 16px;
`;
