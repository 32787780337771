import React, { useCallback, useEffect, useMemo, useState } from "react";
import { cabinetsGetSelectors } from "store/cabinets/get";
import { getCabinets } from "store/cabinets/get/thunk";
import {
  colleagueAddActions,
  colleagueAddSelectors
} from "store/colleagues/add";
import { addColleague } from "store/colleagues/add/thunk";
import {
  colleaguesByCabinetActions,
  colleaguesByCabinetSelectors
} from "store/colleagues/byCabinet";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import { deleteColleague } from "store/colleagues/delete/thunk";
import {
  colleagueUpdateActions,
  colleagueUpdateSelectors
} from "store/colleagues/update";
import { updateColleague } from "store/colleagues/update/thunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { TCabinet, TPerson } from "types";
import { Wrapper } from "./UsersTab.styles";
import { Button, Select } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { UserEditForm, UsersTable } from "components/common/old";
import { restorePassword } from "store/user/restorePassword/thunk";
import { restorePasswordActions } from "store/user/restorePassword";

export const UsersTab = () => {
  const dispatch = useAppDispatch();

  const { cabinets } = useAppSelector(cabinetsGetSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    colleagueAddSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    colleagueUpdateSelectors.getState
  );
  const { isLoading: deleteIsLoading } = useAppSelector(
    colleagueDeleteSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);
  const [userInEdit, setUserInEdit] = useState<TPerson | undefined>(undefined);
  const [selectedCabinet, setSelectedCabinet] = useState<number | undefined>(
    undefined
  );

  const cabinetsOptions = useMemo(
    () =>
      cabinets?.map((cabinet) => ({
        label: cabinet?.name,
        value: cabinet?.cabinet_id
      })),
    [cabinets]
  );

  const getCabinetsArr = useCallback(() => {
    dispatch(getCabinets());
  }, [dispatch]);

  const getColleagues = useCallback(
    (cabinetId: number) => {
      dispatch(getColleaguesByCabinet(cabinetId));
    },
    [dispatch]
  );

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onEdit = useCallback(
    (id: number) => {
      const user = users?.find((user) => user?.user_id === id);
      setUserInEdit(user);
    },
    [users]
  );
  const onCancelEdit = useCallback(() => {
    setUserInEdit(undefined);
  }, []);

  const onAddUser = useCallback(
    (values: TPerson) => {
      dispatch(
        addColleague({
          /* TODO убрать установку cabinet_role_name, cabinet_role_description */
          ...{ cabinet_role_name: "", cabinet_role_description: "" },
          ...values,
          cabinet: cabinets?.find(
            (cabinet) => cabinet?.cabinet_id === selectedCabinet
          ),
          cabinet_role_id: 1
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          selectedCabinet && getColleagues(selectedCabinet);
        });
    },
    [cabinets, dispatch, getColleagues, onCancelAdd, selectedCabinet]
  );
  const onEditUser = useCallback(
    (values: TPerson) => {
      dispatch(updateColleague({ ...userInEdit, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          selectedCabinet && getColleagues(selectedCabinet);
        });
    },
    [dispatch, getColleagues, onCancelEdit, selectedCabinet, userInEdit]
  );
  const onDeleteUser = useCallback(
    (id: number) => {
      dispatch(deleteColleague(id))
        .unwrap()
        .then(() => {
          selectedCabinet && getColleagues(selectedCabinet);
        });
    },
    [dispatch, getColleagues, selectedCabinet]
  );

  const onResetPassword = useCallback(
    (id: number) => {
      dispatch(
        restorePassword({
          email: users?.find((user) => user?.user_id === id)?.email || ""
        })
      );
    },
    [dispatch, users]
  );

  useEffect(() => {
    !cabinets && getCabinetsArr();
  }, [cabinets, getCabinetsArr]);

  useEffect(() => {
    selectedCabinet && getColleagues(selectedCabinet);
  }, [dispatch, getColleagues, selectedCabinet]);

  useEffect(() => {
    return () => {
      dispatch(colleagueAddActions.clearState());
      dispatch(colleagueUpdateActions.clearState());
      dispatch(colleagueDeleteActions.clearState());
      dispatch(colleaguesByCabinetActions.clearState());
      dispatch(restorePasswordActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Select
        placeholder="Выберите кабинет"
        options={cabinetsOptions}
        onChange={setSelectedCabinet}
        value={selectedCabinet}
        style={{ maxWidth: 300 }}
      />
      {selectedCabinet && (
        <>
          {!userInEdit && (
            <Button
              icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
              onClick={isAdding ? onCancelAdd : onAdd}
            >
              {isAdding ? "Отмена" : "Добавить"}
            </Button>
          )}

          {userInEdit ? (
            <>
              <Button icon={<CloseOutlined />} onClick={onCancelEdit}>
                Отмена
              </Button>
              <UserEditForm
                user={userInEdit}
                onSubmit={onEditUser}
                isLoading={updateIsLoading}
                error={updateError}
              />
            </>
          ) : isAdding ? (
            <UserEditForm
              onSubmit={onAddUser}
              isLoading={addIsLoading}
              error={addError}
            />
          ) : (
            <UsersTable
              users={users}
              onEdit={onEdit}
              onDelete={onDeleteUser}
              onResetPassword={onResetPassword}
              isDeleting={deleteIsLoading}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};
