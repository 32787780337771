import React, { useEffect } from "react";
import { userSelectors } from "store/user";
import { useAppDispatch, useAppSelector } from "store/store";
import { Wrapper } from "./Admin.styles";
import { Tabs, TabsProps, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import {
  CabinetsTab,
  LicensesTab,
  LicenseTypesTab,
  UsersTab
} from "./components";
import { cabinetsGetActions } from "store/cabinets/get";

export const Admin = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);

  const tabs: TabsProps["items"] = [
    {
      key: "cabinets",
      label: "Кабинеты",
      children: <CabinetsTab />
    },
    {
      key: "licenses",
      label: "Лицензии",
      children: <LicensesTab />
    },
    {
      key: "licenseTypes",
      label: "Типы лицензии",
      children: <LicenseTypesTab />
    },
    {
      key: "users",
      label: "Пользователи",
      children: <UsersTab />
    }
  ];

  useEffect(() => {
    if (!user?.is_admin) {
      navigate("/", { replace: true, relative: "path" });
    }
  }, [navigate, user]);

  useEffect(() => {
    return () => {
      dispatch(cabinetsGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        Панель администратора
      </Typography.Title>

      <Tabs items={tabs} />
    </Wrapper>
  );
};
