import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ErrorAlert, UserData, UserEditForm } from "components/common/old";
import { useAppDispatch, useAppSelector } from "store/store";
import { Alert, Button, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { colleaguesByCabinetSelectors } from "store/colleagues/byCabinet";
import { userSelectors } from "store/user";
import { getColleaguesByCabinet } from "store/colleagues/byCabinet/thunk";
import {
  colleagueDeleteActions,
  colleagueDeleteSelectors
} from "store/colleagues/delete";
import {
  colleagueUpdateActions,
  colleagueUpdateSelectors
} from "store/colleagues/update";
import { TPerson } from "types";
import { updateColleague } from "store/colleagues/update/thunk";
import { deleteColleague } from "store/colleagues/delete/thunk";
import { CTABlock, Wrapper } from "./Colleague.styles";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

export const Colleague = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    colleagueDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    colleagueUpdateSelectors.getState
  );

  const { user_id } = useParams<{ user_id: string }>();

  const colleague = useMemo(
    () => users?.find((colleague) => colleague?.user_id === Number(user_id)),
    [user_id, users]
  );

  const canUpdateDelete = useMemo(
    () =>
      (user?.cabinet?.cabinet_id === colleague?.cabinet?.cabinet_id &&
        user?.cabinet_role_id === 2) ||
      user?.is_admin,
    [
      colleague?.cabinet?.cabinet_id,
      user?.cabinet?.cabinet_id,
      user?.cabinet_role_id,
      user?.is_admin
    ]
  );

  const getColleagues = useCallback(() => {
    user?.cabinet &&
      dispatch(getColleaguesByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TPerson) => {
      dispatch(updateColleague({ ...colleague, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getColleagues();
        });
    },
    [colleague, dispatch, getColleagues, onCancelEdit]
  );

  const onDelete = useCallback(() => {
    colleague &&
      dispatch(deleteColleague(colleague?.user_id))
        .unwrap()
        .then(() => {
          getColleagues();
        });
  }, [colleague, dispatch, getColleagues]);

  useEffect(() => {
    if (Number(user_id) === user?.user_id) {
      navigate("/personal", { replace: true, relative: "path" });
    }
  }, [user_id, navigate, user?.user_id]);

  useEffect(() => {
    !users && getColleagues();
  }, [dispatch, getColleagues, users]);

  useEffect(() => {
    return () => {
      dispatch(colleagueDeleteActions.clearState());
      dispatch(colleagueUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ margin: 0 }}>
        {colleague?.full_name || "Коллега"}
      </Typography.Title>

      {colleague ? (
        <>
          {canUpdateDelete && (
            <CTABlock>
              <Button
                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                onClick={isEditing ? onCancelEdit : onEdit}
              >
                {isEditing ? "Отмена" : "Редактировать"}
              </Button>

              {!isEditing && (
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={onDelete}
                  loading={deleteIsLoading}
                >
                  Удалить
                </Button>
              )}
            </CTABlock>
          )}

          {!isEditing && <ErrorAlert error={deleteError} />}

          {isEditing ? (
            <UserEditForm
              user={colleague}
              curUser={user}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <UserData user={colleague} />
          )}
        </>
      ) : (
        <Alert message="Данные по пользователю отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
