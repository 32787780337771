import React, { FC, useMemo } from "react";
import { TPerson } from "types";
import { Button, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";

type TUsersTableProps = {
  users?: TPerson[];
  onDelete?: (id: number) => void;
  onEdit?: (id: number) => void;
  onResetPassword?: (id: number) => void;
  isDeleting?: boolean;
};

export const UsersTable: FC<TUsersTableProps> = ({
  users,
  onDelete,
  onEdit,
  onResetPassword,
  isDeleting
}) => {
  const columns: ColumnsType<TPerson> = useMemo(
    () => [
      {
        title: "ФИО",
        dataIndex: "full_name"
      },
      {
        title: "Должность",
        dataIndex: "position"
      },
      {
        key: "reset",
        render: (value: TPerson) => (
          <Button
            onClick={() => onResetPassword?.(value?.user_id)}
            icon={<EyeOutlined />}
          >
            Сбросить пароль
          </Button>
        )
      },
      {
        key: "edit",
        render: (value: TPerson) => (
          <Button
            onClick={() => onEdit?.(value?.user_id)}
            icon={<EditOutlined />}
          >
            Редактировать
          </Button>
        )
      },
      {
        key: "delete",
        render: (value: TPerson) => (
          <Button
            onClick={() => onDelete?.(value?.user_id)}
            icon={<DeleteOutlined />}
            danger
            loading={isDeleting}
          >
            Удалить
          </Button>
        )
      }
    ],
    [isDeleting, onDelete, onEdit, onResetPassword]
  );

  return (
    <Table
      dataSource={users}
      columns={columns}
      pagination={false}
      scroll={{ x: "1000px" }}
    />
  );
};
